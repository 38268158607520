@import '../../../assets/scss/function.scss';

.container{
    width: 100%;
    background: #F5F5F5;
    height: 60px;

    

    .header{
        min-width: 1110px;
        max-width: 1110px;
        margin: 0 auto;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        .headerLogo{
            width: 140px;
            height: 32px;
        }
    }
    .iconDown{
        width: 8px;
        height: 8px;
        margin-left: 4px;
    }
    .imgStyle{
        width: 104px;
        height: 22px;
    }
    .label{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        color: #333333;
        cursor: pointer;
        >div{
            margin-right: 24px;
            position: relative;
        }
        >div:hover{
            color: #64328E;
        }
    }
    .active{
        color: #64328E;
    }
    .aboutUsLabel{
        position: absolute;
        top: -44px;
        left: -12px;
        background: #FFF;
        width: 84px;
        padding-top: 30px;
        z-index: 2;
        opacity: inherit;
        >div{
            height: 40px;
            line-height: 40px;
            text-align: center;
            line-height: 40px;
            color: #333;
        }
        >div:hover{
            color: #64328E;
        }
    }

    @include mobile{
        display: none;
    }
} 