@import '@/assets/scss/function.scss';

.swiperboxs-home {
	width: 100%;
	background: red;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.boxwidth {
	width: 100%;

	@include mobile{
		margin-top: 34mpx;
	}
}

.swiper-wrapper .swiper-slide, .swiper-wrapper .swiper-slide-active, .swiperboxs, .homeimages {
	width: 100%;
	height: 100%;
	overflow: hidden;
	transform: translateY();
}

.homeimages-swiper-pagination{
	position: absolute;
	text-align: center;
	z-index: 2;
	bottom: 30px!important;
	.swiper-pagination-bullet{
		margin: 0 12px!important;
	}

	@include mobile {
		bottom: 16mpx!important;
	}
}


span.point {
  background: #fff;
  height: 2px;
  width: 35px;
	border-radius: 0;
}

.swiperboxs{
	position: relative;

	@include mobile {
		margin-top: -17mpx;
	}
}
.swiper-slide{
	position: relative;
}
.swiper-content{
	position: absolute;
	top: 0;
	left: 50%;
	color: #fff;
	width: 600px;
	transform: translateX(-50%);
	padding-top: 100px;

	@include mobile{
		padding: 24mpx;
		padding-top: 24mpx;
		width: 100%;
	}

	&-title{
		font-size: 32px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 100px;
		// text-align: center;

		@include mobile{
			font-size: 18mpx;
			margin-bottom: 24mpx;
		}
	}

	&-sub-title{
		font-size: 18px;
		font-weight: bold;
		line-height: 27px;
		margin-bottom: 27px;
		@include mobile{
			font-size: 11mpx;
			line-height: 21mpx;
			margin-bottom: 0;
		}
	}

	&-ul {
		font-size: 18px;
		font-weight: 400;
		line-height: 30px;
		@include mobile{
			font-size: 11mpx;
			line-height: 21mpx;
		}
	}

	&-prev, &-next {
		position: absolute;
		width: 48px;

		@include mobile {
			display: none;
		}
	}

	&-prev {
		left: 0;
		top: 0;
		transform: translate3d(-100%, 400%, 0);
	}
	&-next {
		right: 0;
		bottom: 0;
		transform: translate3d(100%, 100%, 0);
	}
}