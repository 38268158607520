@import '../../../../../../assets/scss/function.scss';

.indexcontainer{
    width: 100%;
    .swiperDIV{
        position: relative;
        width: 100%;
        min-height: 600px;
        background: linear-gradient(0deg, #64328E, #64328E), #8909AC;
    }
    .swipercontainer{
        width: 100%;
        height: 600px;
    }
    .leftImg{
        width: 570px;
        height: 600px;
        position: absolute;
        left: 50%;
        top: 50%; 
        margin-left: -465px;
        margin-top: -300px;
    }
    .rightImg{
        width: 254px;
        height: 374px;
        position: absolute;
        right: 50%;
        top: 50%;
        margin-right: -510px;
        margin-top: -187px;
        z-index: 100;
    }
    .pagination{
        right: 44px;
        top: 50%;
        display: flex;
        flex-direction: column;
    }
    span.point {
        width: 8px;
        height: 32px;
        border-radius: 5px;
        margin-bottom: 16px;
        border:1px solid #fff;
        background: inherit;
      }
    span.point.swiper-pagination-bullet-active {
        background: #fff;
    }
    .swiperBox {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 390px;
        min-width: 1190px;
        
        .new {
            width: 1190px;
            margin: 0 auto;
            position: relative;
            overflow: visible;
            height: 390px;
        
            .swiperWidth {
                width: 1920px;
                height: 390px;
                position: absolute;
                top: 0;
                left: -365px;
            }
        }
    }
    
    @include mobile{
        .indexcontainer, .swiperDIV, .rightImg, .swiper-container{
            display: none;
        }
    }
}