.brandcontainer{
    min-width: 1110px;
    max-width: 1110px;
    margin: 0 auto;
    position: relative;
    .name{
        font-size: 18px;
        color: #333;
        margin: 24px auto;
        text-align: center;
    }
    .swiper-button-prev,.swiper-button-next{
        color: #666;
    }
    .swiper-button-disabled{
        color: #cccccc;
    }
    .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
        opacity: inherit;
    }
    .swiperDiv{
        width: 940px;
        margin:72px auto 40px;

        .listDiv{
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            width: 956px;
            min-width: 956px;
            max-width: 956px;
    
            .listLi{
                width: 140px;
                height: 140px;
                min-width: 140px;
                max-width: 140px;
                margin:0 16px 16px 0;
                >img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}