.container{
    min-width: 1110px;
    max-width: 1110px;
    margin: 0 auto;
    position: relative;
    .labelYe{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        >div{
            padding: 20px 0;
            margin-right: 120px;
            font-size: 18px;
            color: #333333;
            cursor: pointer;
        }
        >div:last-child{
            margin-right: 0px;
        }

        .active{
            color: #64328E;
            border-bottom: 2px solid #64328E;
        }
    }
}