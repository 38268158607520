@import '../../../assets/scss/function.scss';


.container{
    min-width: 1110px;
    margin: 0 auto 82px;
    background: #fff;
    >div{
        text-align: center;
        font-size: 12px;
        color: #999999;
        margin-bottom: 12px;
        >span{
            cursor: pointer;
        }
        >span:hover{
            color: red;
        }
    }
    .gonganju{
        display: flex;
        align-items: center;
        justify-content: center;
        .gonganjuImg{
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }
    @include mobile {
        width: 100%;
    }
}