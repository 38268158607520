.responsibility{
	min-width: 960px;
	max-width: 960px;
	margin: 0 auto;
}
.titlerlt {
	margin: 0 auto;
	overflow: hidden;
	position: relative;

	.zhuangshi {
		position: absolute;
		top: 23px;
		left: 5px;
		width: 36px;
		height: 36px;
	}
	h2{
		padding: 32px 0 24px 20px;
		font-size: 18px;
		color: #333;
		font-weight: 550;
	}
	
	p{
		font-size: 16px;
		line-height: 24px;
		padding: 3px 3px;
		float: left;
		margin-left: 20px;
		line-height: 24px;
	}
}

.connerlt {
	height: 200px;
	overflow: hidden;
	padding-left: 20px;
	margin-top: 26px;

	img {
		width: 296px;
		height:200px;
		margin-right: 24px;
		float: left;
	}
	img:last-child {
		margin-right: 0px;
	}
}
.centerlt{
	h3{
		color:#666;
		text-align: center;
		font-weight: normal;
		font-size: 16px;
		margin:24px 0 41px 0;
	}
}
