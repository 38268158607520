input{     outline:0px; }
.invoicecontainer{
    width:1200px;
    margin: 0 auto;
    position: relative;
    padding: 100px 0 100px;
    min-height:700px;
    .invoicetitle{
        font-size: 30px;
        font-weight: bold;
        color: #860093;
        font-weight: bold;
        font-family: "Microsoft YaHei";
        text-align: center;
        padding: 20px 0;
    }
    .invoiceinputDiv{
        width: 700px;
        margin: 0 auto;
        background: #E5E5E5;
        .isNoinfo{
            display: flex;
            padding: 12px;
            align-items: center;
            justify-content: center;
            >img{
                width: 16px;
                height: 15px;
                margin-right: 12px;
            }
        }
        >input{
            width: 485px;
            height: 48px;
            padding: 2px 8px;
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
            border: 1px solid #c2c2c2;
            margin: 20px auto;
            margin: 20px 108px;
            border-radius: 4px;
        }
        .searchBtn{
            margin-top: 20px;
            width: 114px;
            height: 36px;
            cursor: pointer;
            line-height: 36px;
            text-align: center;
            color: #fff;
            margin: 0 auto;
            font-size: 20px;
            background: #7E1A83;
            margin-bottom: 20px;
        }
        .clearfloat{
            clear:both;height:1px;
        }
        .invoiceInfo{
            margin-top: 20px;
            display: flex;
            flex-wrap:wrap;
            background: #fff;
            >div{
                min-width: 50%;
                max-width: 50%;
                line-height: 50px;
                font-size: 12px;
                text-indent: 20px;
                color: #000;
                >span:first-child{
                    display: inline-block;
                    width: 95px;
                    text-align: right;
                    color: #999;
                }
            }
            .lastBtn{
                display: flex;
                justify-content: flex-start;
                >img{
                    width: 40px;
                    height: 40px;
                    margin-left: 84px;
                }
                >span{
                    display: none;
                }
            }
        }
    }
}
.errorTost{
    position: fixed;
    width: 169px;
    height: 48px;
    border-radius: 6px;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    line-height: 48px;
    font-size: 15px;
    left: 50%;
    top: 50%;
    margin-top: -24px;
    margin-left: -84px;
}
@media screen and (max-width:750px) {
    .invoicecontainer{
        padding-top: 0px;
        width: 100%;
        .invoicetitle{
            width: 100%;
            font-size: 18px;
            font-weight: 500;
            color: #333;
        }
        .invoiceinputDiv{
            width: 100%;
            min-height: 736px;
            >input{
                margin: 12px 12px;
                width: 94%;
                border: 0px;
                font-size: 13px;
                font-weight: 400;
            }
            .searchBtn{
                margin: 0 12px;
                width: 94%;
                background: #CCC;
                border-radius: 20px;
                font-size: 15px;
            }
            .active{
                background: #8909AC;
                color: #fff;
            }
            .invoiceInfo{
                flex-direction: column;
                margin: 18px 12px;
                border-radius: 6px;
                position: relative;
                >div{
                    width: 100%;
                    border-bottom: 0.5px solid #F2F2F2;
                    max-width: 100%;
                    min-width: 100%;
                    font-size: 15px;
                }
                .lastBtn{
                    background: #8909AC;
                    border-radius: 20px;
                    position: absolute;
                    bottom: -66px;
                    left: 0px;
                    cursor: pointer;

                    >span{
                        line-height: 40px;
                        color: #fff;
                        text-align: center;
                        display: inline-block;
                        width: 100%;
                    }
                    >img{
                        display: none;
                    }
                }
            }
        }
    }
}