.bigStorycontainer{
    min-width: 1110px;
    max-width: 1110px;
    margin: 0 auto;
    position: relative;
    >div{
        line-height: 40px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        position: relative;
        .spanline{
            position: absolute;
            left: 26px;
            top: 20px;
            border-left: 0.5px dotted #64328E;
            height: 180%;
        }
        .lastSpanLine{
            display: none;
        }
    }
    .title{
        font-size: 16px;
        color: #64328E;
        font-weight: 600;
        margin-top: 36px;
    }
    p{
        font-size: 16px;
        line-height: 24px;
        color: #666666;
        max-width: 1039px;
    }
    .spanCurcle{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #64328E;
        margin: 0 40px 0 23px;
    }
}