@mixin pad {
	@media (max-width: 1024px) {
	  @content;
	}
  }
  
  @mixin mobile {
	@media (max-width: 750px) {
	  @content;
	}
  }