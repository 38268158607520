.mediacontainer{
    min-width: 916px;
    max-width: 916px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 40px;
    .mediatitle{
        font-size: 18px;
        color: #333;
        font-weight: 600;
        text-align: center;
        padding-top: 24px;
    }
    .twoTitle{        
        padding:24px;
        font-weight: 600;
        font-size: 16px;
        color: #333;
    }

    .hostLi{
        >img{
            width: 100%;
            height: 100%;
        }
    }
    .addMediatLi{
        >img{
            width: 100%;
            height: 100%;
        }
        .frame{
            cursor: pointer;
            width: 48px;
            height: 48px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -24px;
            margin-left: -24px;
        }
    }
    .video-react .video-react-big-play-button{
        width: 1.5em;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin-top: -0.75em;
        margin-left: -0.75em; 
    }
}